import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const changePasswordProcess =()=>{    
    const hata = ref(null);
    const router = useRouter();
    const path = apiPath;
    let users = JSON.parse(localStorage.getItem('user'));
    const getChangePasswordProcess = async()=>{
        hata.value = null;
        try {
            const res = await fetch(path + "user/changePasswordProcess", {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    "TokenKey": localStorage.getItem('token'),
                    "UserId":users.id,
                    "RequestMethodName" :"/api/user/changePasswordProcess",
                    "RequestMethodType" : "POST"
                }), 
            })
            const data = await res.json();
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})              
            }
            else{
                return data
            }
        } catch (error) {
            hata.value = error.message
            console.log(hata.value);
        }
    }
    
    return {getChangePasswordProcess}
}

export default changePasswordProcess