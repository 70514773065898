<script>
import appConfig from "@/app.config";
import changePasswordCheck from "../../../state/api/ChangePasswordCheck";
import changePasswordProcess from "../../../state/api/changePasswordProcess";
import userChangePassword from "../../../state/api/userChangePassword";
/**
 * Email verification component
 */
export default {
  page: {
    title: "Email verification",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
        secureCode:"",
        secureCodeError:"",
        countdown: 300,
        intervalId: null,
        step1:true,
        step2:false,
        step3:false,
        restartVerifyEmail:false,
        password:""
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
  },
  methods: {
    startCountdown() {
        this.countdown = 300
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
            document.querySelector("#btn-verify-email").disabled = true;
            this.secureCodeError = "You could not verify the password within the specified time. Please verify your email again!"
            this.restartVerifyEmail = true;
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    verifyEmail(){
        const data = this;
        async function postVerifyEmail() {
            const {getChangePasswordCheck} = changePasswordCheck()
            await getChangePasswordCheck(data.secureCode).
            then(res=>{
                if(res.isSuccess ==true){
                    data.step1 = false;
                    data.step2 = true;
                }
                else{
                    data.secureCodeError = res.errorMessage;
                    data.step1 = true;
                    data.step2 = false;                    
                }
            })
        }
        postVerifyEmail();
    },
    async changePassword() {
        const data = this;
        this.secureCodeError = "";
        this.restartVerifyEmail = false;
        const btn = document.querySelector("#btn-verify-email");

        async function postChangePassword() {
            const { getChangePasswordProcess } = changePasswordProcess();
            try {
            const res = await getChangePasswordProcess();
            if (res.isSuccess) {
                btn.disabled = false;
                this.startCountdown();
            } else {
                data.secureCodeError = res.errorMessage;
                btn.disabled = false;
                this.startCountdown();
            }
            } catch (error) {
                console.error("Error in postChangePassword:", error);
            }
        }
        postChangePassword.bind(this)();
    },
    userChangePassword(){
        const data = this;
        async function passwordChange() {
            const { getUserChangePassword } = userChangePassword();
            try {
            const res = await getUserChangePassword(data.password);
            if (res.isSuccess) {
                data.step2 =false;
                data.step3 =true;
                data.$router.push({ name: "default" });
            } else {
                data.secureCodeError = res.errorMessage;
            }
            } catch (error) {
            console.error("Error in postChangePassword:", error);
            }
        }
        passwordChange.bind(this)();
    }
  },
  mounted() {
    this.startCountdown();
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <!-- end row -->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bxs-envelope h1 mb-0 text-primary"></i>
                    </div>
                  </div>
                    <div class="p-2 mt-4" v-show="step1">
                        <h4>Verify your email</h4>
                        <p>
                            We have sent you a verification email to <span class="fw-semibold">example@abc.com</span>, please check it
                        </p>
                        <form @submit.prevent="verifyEmail()">
                            <div>
                            <input
                                type="secureCode"
                                class="form-control"
                                id="secureCode"
                                v-model="secureCode"
                                placeholder="Enter Source Code"
                                required
                            />
                            </div>                        
                            <div class="d-flex justify-content-between mt-3">
                                <div class="time">
                                    <span>Remaining Time: {{ formattedTime }}</span>
                                </div>
                                <button class="btn btn-success w-md" id="btn-verify-email" type="submit">Verify email</button>
                            </div>
                        </form>   
                        <div class="mt-4">
                            <p class="text-danger">{{ secureCodeError }}</p>
                        </div>
                        <div class="mt-4" v-show="restartVerifyEmail">
                            <button type="button" class="btn btn-primary" @click="changePassword">Send Password</button>
                        </div> 
                    </div>
                    <div class="p-2" v-show="step2">
                        <div class="text-center mb-4" role="alert">
                            Enter your Email and instructions will be sent to you!
                        </div>
                        <form class="form-horizontal" @submit.prevent="userChangePassword">
                            <div class="form-group">
                                <label for="pwd">Password</label>
                                <input
                                type="password"
                                v-model="password"
                                class="form-control"
                                id="pwd"
                                placeholder="Enter password"
                                required
                                />
                            </div>
                            <div class="form-group row mt-3">
                                <div class="col-12 text-end">
                                <button class="btn btn-primary w-md" type="submit">
                                    Reset
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="p-2" v-show="step3">
                        <div class="alert alert-success text-center mb-4" role="alert">
                            Your password has been updated!
                        </div>
                        <p class="text-center">You are directed to the home page...</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>
              <a href="/" class="fw-medium text-primary"> Return to Home Page </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>